<template>
  <v-container class="my-1">
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack()"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-snackbar top center v-model="snackbar">
      {{ $t("product details updated successfully") }}!
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          v-bind="attrs"
          @click="snackbar = false"
          :timeout="timeout"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-form ref="form">
      <v-layout row wrap justify-space-between class="mb-2">
        <v-flex xs12 sm4 md4 lg4>
          <h2 class="title text-capitalize">
            {{ $t("edit details for product") }} #{{ product.barcode }}
          </h2>
        </v-flex>
        <v-flex xs12 sm4 md4 lg4 class="text-right">
          <v-btn
            small
            dark
            class="mr-4"
            :color="$store.state.primaryColor"
            :loading="loading"
            @click="updateProduct"
          >
            <v-icon small left>save</v-icon>
            <span class="caption">{{ $t("save changes") }}</span>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>

      <div v-if="pleaseWait" class="mt-3">
        <PleaseWait></PleaseWait>
      </div>
      <v-container class="my-1" v-else>
        <v-layout row wrap>
          <v-flex xs12 sm12 md3 lg3 class="text-center elevation-2">
            <v-card class="mx-auto" max-width="344">
              <v-img :src="product.avatar" height="200px" contain></v-img>
            </v-card>
            <input
              type="file"
              @change="updatePicture"
              dense
              small-chips
              :rules="rules.emptyField"
              class="caption mt-2"
            />
          </v-flex>

          <v-flex xs12 sm12 md9 lg9>
            <v-list dense class="elevation-1 pa-0">
              <v-list-item class="caption justify-space-between">
                <v-layout row wrap class="mt-2">
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("product name") }} :
                    </span>
                    <v-text-field
                      outlined
                      dense
                      v-model="product.name"
                      :rules="rules.emptyField"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("product category") }}:
                    </span>
                    <v-autocomplete
                      outlined
                      dense
                      item-text="category"
                      item-value="id"
                      :items="categories"
                      v-model="product.categoryID"
                      @change="setCategory(product.categoryID)"
                      :rules="rules.emptyField"
                    ></v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense class="elevation-1 pa-0">
              <v-list-item class="caption justify-space-between">
                <v-layout row wrap class="mt-2">
                  <v-flex xs12 sm6 md4 lg4 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("expiry date") }}:
                    </span>
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="product.expiryDate"
                          append-icon="mdi-calendar"
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="product.expiryDate"
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg4 class="px-1">
                    <span class="font-weight-medium text-capitalize">
                      {{ $t("description") }} :
                    </span>
                    <v-textarea
                      outlined
                      dense
                      auto-grow
                      rows="1"
                      v-model="product.description.about"
                      :rules="rules.emptyField"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg4 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("brand") }}:
                    </span>
                    <v-text-field
                      outlined
                      dense
                      v-model="product.description.brand"
                      :rules="rules.emptyField"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense class="elevation-1 pa-0">
              <v-list-item class="caption justify-space-between">
                <v-layout row wrap class="mt-2">
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("model") }}:
                    </span>
                    <v-text-field
                      outlined
                      dense
                      v-model="product.description.model"
                      :rules="rules.emptyField"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("type") }}:
                    </span>
                    <v-text-field
                      outlined
                      dense
                      v-model="product.description.type"
                      :rules="rules.emptyField"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense class="elevation-1 pa-0">
              <v-list-item class="caption justify-space-between">
                <v-layout row wrap class="mt-2">
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize">
                      {{ $t("size") }}:
                    </span>
                    <v-text-field
                      outlined
                      dense
                      v-model="product.description.size"
                      :rules="rules.emptyField"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("color") }}:
                    </span>
                    <v-text-field
                      outlined
                      dense
                      v-model="product.description.color"
                      :rules="rules.emptyField"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense class="elevation-1 pa-0">
              <v-list-item class="caption justify-space-between">
                <v-layout row wrap class="mt-2">
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("product origin") }}* :
                    </span>
                    <v-autocomplete
                      outlined
                      dense
                      v-model="product.origin"
                      :items="countryNameList"
                      :rules="rules.emptyField"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("remaining stock") }}:
                    </span>
                    <v-text-field
                      outlined
                      dense
                      v-model="product.quantity.inStock"
                      :rules="rules.emptyField"
                      @keypress="digits"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense class="elevation-1 pa-0">
              <v-list-item class="caption justify-space-between">
                <v-layout row wrap class="mt-2">
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("delivered date") }}:
                    </span>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="product.date.ordered"
                          append-icon="mdi-calendar"
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="product.date.ordered"
                        @input="menu1 = false"
                        :max="product.date.delivered"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("delivered date") }}:
                    </span>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="product.date.delivered"
                          append-icon="mdi-calendar"
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="product.date.delivered"
                        @input="menu2 = false"
                        :min="product.date.ordered"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>
            <v-list dense class="elevation-1 pa-0">
              <v-list-item class="caption justify-space-between">
                <v-layout row wrap class="mt-2">
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize">
                      {{ $t("buying price") }}:
                    </span>
                    <v-text-field
                      outlined
                      dense
                      @keypress="digits"
                      v-model="product.price.buying"
                      :rules="rules.emptyField"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("selling price") }}:
                    </span>
                    <v-text-field
                      outlined
                      dense
                      @keypress="digits"
                      v-model="product.price.selling"
                      :rules="rules.emptyField"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense class="elevation-1 pa-0">
              <v-list-item class="caption justify-space-between">
                <v-layout row wrap class="mt-2">
                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("limit price") }} :
                    </span>
                    <v-text-field
                      outlined
                      dense
                      @keypress="digits"
                      v-model="product.price.limit"
                      :rules="rules.emptyField"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md6 lg6 class="px-1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("discount") }} :
                    </span>
                    <v-text-field
                      outlined
                      dense
                      @keypress="digits"
                      v-model="product.price.discount"
                      :rules="rules.emptyField"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-list-item>
            </v-list>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import fb from "firebase/app";
import PleaseWait from "@/components/templates/PleaseWait";
import { digits } from "@/plugins/functions";
export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    loading: false,
    snackbar: false,
    pleaseWait: true,
    timeout: 2000,
    menu1: false,
    menu2: false,
    menu3: false,
    product: "",
    file: "",
    categories: [],
  }),

  computed: {
    imageName() {
      return this.product.businessID + "-" + this.product.name;
    },
    countryNameList() {
      let countries = this.$store.getters.getCountries;

      let countryNames = countries.map((item) => item.name);
      return countryNames;
    },
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },

  created() {
    this.getProduct();
    this.getCategories();
  },

  methods: {
    digits,
    getProduct() {
      db.collection("products")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.product = doc.data();
          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
        });
    },

    updatePicture(event) {
      this.file = event.target.files[0];
      this.product.avatar = URL.createObjectURL(this.file);
    },

    getCategories() {
      db.collection("productCategory")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.categories.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          //  console.log(JSON.stringify(this.categories))
        });
    },

    getCategoryName(id) {
      let catId = this.categories.find((item) => item.id == id);
      return catId.category;
    },

    updateProduct() {
      if (this.file != "") {
        this.loading = true;
        let uploadTask = fb
          .storage()
          .ref("stock_images/" + this.imageName)
          .put(this.file);

        uploadTask.on(
          "state_changed",
          () => {},
          () => {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
              this.product.avatar = downloadLink;

              db.collection("products")
                .doc(this.$route.params.id)
                .update(this.product)
                .then(() => {
                  this.loading = false;
                  this.file = "";
                  this.snackbar = true;
                  setTimeout(() => this.goBack(), 2000);
                })
                .catch((error) => {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      } else {
        this.loading = true;
        db.collection("products")
          .doc(this.$route.params.id)
          .update(this.product)
          .then(() => {
            this.loading = false;
            this.file = "";
            this.snackbar = true;
            setTimeout(() => this.goBack(), 2000);
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          });
      }
    },
    setCategory(id) {
      //set category id to product details
      let category = this.categories.find((x) => x.id === id);

      this.product.categoryID = category.id;
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
